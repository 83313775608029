/**
 * Here you can define variables that are loaded only when the compiled project is run with the command buildpdn
 */
export const environment = {
  /**
   * indicates if the environment is productive, when true the angular configuration is enabled for production applications.
   */
  production: false,
  api: 'https://gestorautorizacionsaludapi.labsura.com/gestautosal/'
};
